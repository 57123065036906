import React, { useContext, useState, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { app, host } from "../../config";
import { AuthContext } from "../authProvider/authProvider";
import { getAuth, signOut } from "firebase/auth";
import { doc, getDoc, getFirestore, DocumentData } from "firebase/firestore";
import pic from "components/assets/step-1.png";
import pic1 from "components/assets/emp.jpg";
import pic2 from "components/assets/maint.jpg";
import pic3 from "components/assets/census.png";
import pic4 from "components/assets/Claims.png";
import pic5 from "components/assets/docUpload.png";
import pic6 from "components/assets/payments.png";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
//import MaintenanceIcon from '@mui/icons-material/Maintenance';
import ViewListIcon from "@mui/icons-material/ViewList";
//import ClaimIcon from '@mui/icons-material/Claim';
import UploadFileIcon from "@mui/icons-material/UploadFile";
import PaymentIcon from "@mui/icons-material/Payment";
import InfoIcon from "@mui/icons-material/Info";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import BuildIcon from "@mui/icons-material/Build";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import SearchIcon from "@mui/icons-material/Search";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import TableChartIcon from "@mui/icons-material/TableChart";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import RateReviewIcon from "@mui/icons-material/RateReview";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import AssessmentIcon from "@mui/icons-material/Assessment";

import "./ThreeCards.css";
import Modal from "react-modal";
import { Button } from "@mui/material";
import CustomAlert from "components/alert/CustomAlert";

interface CardProps {
  title: string;
  content: string;
  IconComponent: React.ReactNode;
  onCardClick: () => void; // The function to execute when the card is clicked
  // href?: string;
  tooltipText: string;
}

const Card: React.FC<CardProps> = ({ title, content, IconComponent, onCardClick, tooltipText }) => {
  const [hover, setHover] = useState(false);

  const cardStyle = hover ? { backgroundColor: "blue", boxShadow: "0 4px 8px rgba(0,0,0,0.1)" } : {};

  // console.log('Hover state:', hover);
  // console.log('Card style:', cardStyle);

  return (
    <div className="card" onClick={onCardClick}>
      <span className="tooltip">{tooltipText}</span>
      <h2>{title}</h2>
      <div className="card-iconHome">{IconComponent}</div>
      <p>{content}</p>
    </div>
  );
};

console.log(pic);
console.log("this is pic");
console.log(typeof pic);

const auth = getAuth();

const ThreeCards: React.FC = () => {
  const authContext = useContext(AuthContext); // Get Auth context
  const [loading, setLoading] = useState(true); // Track loading state
  const [needAssignment, setNeedAssignmnent] = useState(true);
  const [isAlertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState<string>("");
  if (!authContext) {
    throw new Error("AuthContext is undefined");
  }


  const { currentUser, suspended, firstName, lastName, email, agencyName, role, canViewCommissions, tileAccess, authorizedGroupNumbers, authorizedGroupNumber } =
    authContext;


  const closeAlert = () => {
    setAlertOpen(false);
  };

  const modalStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      alignItems: "center",
      justifyContent: "center",
      transform: "translate(-50%, -50%)",
      width: "30%", // This line controls the width of the modal
      height: "20%", // This line controls the height of the modal
    },
  };

  // If there's no current user, navigate to the login page

  const navigate = useNavigate(); // Hook to navigate between routes

  const isUnassigned = () => role === "null" || (role === "broker" && (authorizedGroupNumbers == null || authorizedGroupNumbers.length < 1)) || (role === "employer" && (authorizedGroupNumber == null || authorizedGroupNumber === ""));

  const isAdmin = () => role === "admin";

  const hide = () => false;

  const isBroker = () => role === "broker";

  const viewCommissions = () => canViewCommissions === true;

  const isEmployer = () => role === "employer";

  const isInternalUGP = () => role === "internal_ugp";

  // console.log("THIS IS CURRENT USER FOR BASEPAGE")
  // console.log(currentUser)

  const closeModal = () => {
    setNeedAssignmnent(false);
    signOut(auth);
  };

  if (!currentUser) {
    return <Navigate to="/login" />;
  }
  console.log("testing tile access");
  console.log(tileAccess);

  // Function to navigate to the upload page
  const goToUploadPage = () => {
    navigate("/upload");
  };

  const goToEmploymentEnrollment = () => {
    navigate("/enroll");
  };
  // Function to navigate to the admin portal
  const goToAdminPortal = () => {
    navigate("/admin");
  };

  const goToAdminPanel = () => {
    navigate("/adminPanel");
  };

  const goToClaims = () => {
    // navigate("/claims");
  };

  const goToClaimsLookup = () => {
    navigate("/claimsLookup");
  };

  const goToEmployeeMaintenance = () => {
    navigate("/employeeMaintenance");
  };

  const goToOptienhanceReg = () => {
    navigate("/optienhanceReg");
  };

  const goToInvoice = () => {
    navigate("/invoice");
  };

  const goToPolicyAndCerts = () => {
    navigate("/policyAndCerts");
  };

  const goToCensus = () => {
    navigate("/census");
  };

  const goToBasePage = () => {
    navigate("/basePage");
  };

  const goToCardVerification = () => {
    navigate("/verifyCensus");
  };

  const goToPaymentsPage = () => {
    navigate("/payments");
  };

  const goToCensusUpload = () => {
    navigate("/censusUpload");
  };

  const goToimplementationStatus = () => {
    navigate("/implementationStatus");
  };

  const goToCommissions = () => {
    navigate("/claimsCommission");
  };

  const goToRater = () => {
    window.location.href = "https://secure.optimedhealth.com/rating";
  };

  const goToClaimsSubmission = () => {
    window.open("https://optimedhealth.formstack.com/forms/gcf", "_blank");
  };

  const goToEligibilityPage = () => {
    navigate("/eligibility")
  }

  const goToGroupImplementation = () => {
    navigate("/groupImplementation")
  }

  const goToClaimsUpload = () => {
    navigate("/claimsUpload")
  }

  var tk: string;
  currentUser
    ?.getIdToken(false)
    .then((idToken: string) => {
      tk = idToken;
    })
    .catch((error: any) => {
      console.log(error);
    });

  // If the user is suspended, sign them out and navigate to the login page
  if (suspended === true) {
    signOut(auth)
      .then(() => {
        console.log("Signed out");
        return <Navigate to="/login" />;
      })
      .catch((error) => {
        console.error("Error signing out", error);
      });
  }

  // Helper function to determine if a card should be visible
  const isVisible = (title: string) => {
    if (tileAccess != null || tileAccess !== undefined) {
      return !tileAccess.includes(title);
    } else {
      return true;
    }
    //return true;
  };

  const handleRequestCommissions = () => {
    const data = {
      firstName: firstName,
      lastName: lastName,
      userEmail: email,
      agencyName: agencyName,
    };
    fetch(`${host}/api/implementation/requestedCommissions`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${tk}`,
      },
      body: JSON.stringify(data),
    }).then((response: Response) => {
      if (response.status == 200) {
        setAlertMessage(
          `You have requested access to commissions for ${agencyName}. An OptiMed Representative will be in touch.`
        );
        setAlertOpen(true);
      } else {
        setAlertMessage(`An error has occurred please try again later.`);
        setAlertOpen(true);
      }
    });
  };


  return (
    <div>
      {isUnassigned() && (
        <div>
          <Modal isOpen={needAssignment} onRequestClose={closeModal} style={modalStyles}>
            <h2>Pending Group Assignment</h2>
            <p>
              Your account is still being set up by our team! If this is an error please contact your account manager.
            </p>
          </Modal>
        </div>
      )}

      {(isEmployer() || isAdmin() || isBroker() || isInternalUGP()) && (
        <div className="section">
          <h2 className="section-title">Employer Tools</h2>
          <div className="cards-container">

            {isVisible("/enroll") && (
              <Card
                title="Enroll a New Member"
                content=""
                IconComponent={<PersonAddIcon className="card-iconHome" />}
                onCardClick={goToEmploymentEnrollment}
                tooltipText=""
              />
            )}


            {isVisible("/employeeMaintenance") && (
              <Card
                title="Employee Maintenance"
                content=""
                IconComponent={<PeopleAltIcon className="card-iconHome" />}
                onCardClick={goToEmployeeMaintenance}
                tooltipText=""
              />
            )}

            {isVisible("/census") && (
              <Card
                title="Download Census"
                content=""
                IconComponent={<ViewListIcon className="card-iconHome" />}
                onCardClick={goToCensus}
                tooltipText=""
              />
            )}

            {isVisible("/payments") && (
              <Card
                title="Make Payments"
                content=""
                IconComponent={<PaymentIcon className="card-iconHome" />}
                onCardClick={goToPaymentsPage}
                tooltipText=""
              />
            )}

            {isVisible("/claimsLookup") && (
              <Card
                title="Claims Look up"
                content=""
                IconComponent={<SearchIcon className="card-iconHome" />}
                onCardClick={goToClaimsLookup}
                tooltipText=""
              />
            )}

            {isVisible("Claim Submission") && (
              <Card
                title="Claim Submission"
                content=""
                IconComponent={<AssessmentIcon className="card-iconHome" />}
                onCardClick={goToClaimsSubmission}
                tooltipText=""
              />
            )}

            {isVisible("/invoice") && (
              <Card
                title="Invoices"
                content=""
                IconComponent={<ViewListIcon className="card-iconHome" />}
                onCardClick={goToInvoice}
                tooltipText=""
              />
            )}

            {isVisible("/policyAndCerts") && (
              <Card
                title="Policy And Certs"
                content=""
                IconComponent={<ViewListIcon className="card-iconHome" />}
                onCardClick={goToPolicyAndCerts}
                tooltipText=""
              />
            )}
            {/* News/Information Cards */}
            {/* Coming soon */}
          </div>
        </div>
      )}
      {(isBroker() || isAdmin() || isInternalUGP()) && (
        <div className="section">
          <h2 className="section-title">Broker Tools</h2>
          <div className="cards-container">
            {/* Broker Cards */}

            {/* <Card
              title="Upload Documents"
              content=""
              IconComponent={<UploadFileIcon style={{ fontSize: "250px" }} />}
              onCardClick={goToUploadPage}
              tooltipText=""
            /> */}

            {hide() && isVisible("/claimsCommission") && (

              <Card
                title="Commissions"
                content=""
                IconComponent={<ViewListIcon className="card-iconHome" />}
                onCardClick={goToCommissions}
                tooltipText=""
              />
            )}

            {isVisible("/implementationStatus") && (
              <Card
                title="Implementation Status"
                content=""
                IconComponent={<SystemUpdateAltIcon className="card-iconHome" />}
                onCardClick={goToimplementationStatus}
                tooltipText=""
              />
            )}
            {isVisible("/upload") && (
              <Card
                title="Upload Documents"
                content=""
                IconComponent={<UploadFileIcon className="card-iconHome" />}
                onCardClick={goToUploadPage}
                tooltipText=""
              />
            )}
            {isVisible("OptiRater Product Quoting") && (
              <Card
                title="OptiRater Product Quoting"
                content=""
                IconComponent={<AssessmentIcon className="card-iconHome" />}
                onCardClick={goToRater}
                tooltipText=""
              />
            )}


            {/* ... */}
          </div>
        </div>
      )}

      {(isAdmin() || isInternalUGP()) && (
        <div className="section">
          <h2 className="section-title">Optimed Administrator Tools</h2>
          <div className="cards-container">

            {isVisible("/censusUpload") && (
              <Card
                title="Census Upload"
                content=""
                IconComponent={<TableChartIcon className="card-iconHome" />}
                onCardClick={goToCensusUpload}
                tooltipText=""
              />
            )}
            {isVisible("/optienhanceReg") && (
              <Card
                title="OptiEnhance Registration Table"
                content=""
                IconComponent={<SystemUpdateAltIcon className="card-iconHome" />}
                onCardClick={goToOptienhanceReg}
                tooltipText=""
              />
            )}
            {isVisible("/eligibility") && (
              <Card
              title="Eligibility File Lookup"
              content=""
              IconComponent={<SystemUpdateAltIcon className="card-iconHome" />}
              onCardClick={goToEligibilityPage}
              tooltipText=""
            />
            )}
            {isVisible("/eligibility") && (
              <Card
              title="Group Setup"
              content=""
              IconComponent={<SystemUpdateAltIcon className="card-iconHome" />}
              onCardClick={goToGroupImplementation}
              tooltipText=""
            />
            )}
            {isVisible("/eligibility") && (
              <Card
              title="Claims Upload"
              content=""
              IconComponent={<SystemUpdateAltIcon className="card-iconHome" />}
              onCardClick={goToClaimsUpload}
              tooltipText=""
            />
            )}
            {isAdmin() && isVisible("/adminPanel") && (

              <Card
                title="Admin Control Panel"
                content=""
                IconComponent={<AdminPanelSettingsIcon className="card-iconHome" />}
                onCardClick={goToAdminPanel}
                tooltipText=""
              />
            )}
            {/* News/Information Cards */}
            {/* Coming soon */}
          </div>
        </div>
      )}

      {(isAdmin() || isBroker() || isInternalUGP()) && hide() && (
        <div className="section">
          <h2 className="section-title">Products</h2>
          <div className="cards-container">
            {isVisible("OptiRater Product Quoting") && (
              <Card
                title="OptiRater Product Quoting"
                content=""
                IconComponent={<AssessmentIcon className="card-iconHome" />}
                onCardClick={goToRater}
                tooltipText=""
              />
            )}
          </div>
        </div>
      )}
      {hide() && (
        <div className="section">
          <h2 className="section-title">News/Information</h2>
          <div className="cards-container">
            {isVisible("TBD") && (
              <Card
                title="TBD"
                content=""
                IconComponent={<NewspaperIcon className="card-iconHome" />}
                onCardClick={goToimplementationStatus}
                tooltipText=""
              />
            )}
            {/* News/Information Cards */}
            {/* Coming soon */}
          </div>
        </div>
      )}
      {isBroker() && !viewCommissions() && (
        <>
          <>
            <button className="commissions-button" onClick={handleRequestCommissions}>
              Need Access to Commissions?
            </button>
          </>
          <CustomAlert message={alertMessage} isOpen={isAlertOpen} onClose={closeAlert} />
        </>
      )}
    </div>
  );
};

export default ThreeCards;
